import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"
import VisibilitySensor from "react-visibility-sensor"
import { motion } from "framer-motion"

import { useOnScreen } from "../../hooks"
import ContentWrapper from "../../styles/contentWrapper"
import Underlining from "../../styles/underlining"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  margin-top: 3rem;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
    .section-title {
      margin-bottom: -2rem;
      padding-right: 2.5rem;
      padding-left: 2.5rem;
      margin-bottom: 1rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-right: 0;
        padding-left: 0;
      }
    }
    .experiences {
      display: flex;
      flex-direction: column;
      margin-top: -2.5rem;
      padding: 2.5rem 2.5rem;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        flex-direction: column;
        margin-top: 0;
        padding: 0;
        overflow: visible;
      }
    }
  }
`

const StyledExperience = styled(motion.div)`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0;
  margin-bottom: 2rem;
  flex-shrink: 0;
  padding-right: 2.5rem;
  max-width: 20rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    max-width: 25rem;
    margin-top: -0.5rem;
    padding-right: 5rem;
    margin-bottom: 0rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: space-between;
    flex-shrink: 1;
    max-width: 62.5rem;
    margin-top: 2rem;
    margin-bottom: 0rem;
    padding-right: 0;
    flex-direction: row;
  }
  .details {
    width: 100%;
    max-width: 100rem;
    display: flex;
    flex-direction: column;
    margin-top: 2.875rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 1rem;
    }

    .category {
      font-size: 0.875rem;
      line-height: 1rem;
      text-transform: uppercase;
      letter-spacing: +1px;
    }
    .title {
      margin-top: 0.625rem;
      margin-bottom: -0.1rem;
      font-size: 1.375rem;
      line-height: 1.625rem;
      font-weight: 700;
    }
    .where {
        margin-top: 0.625rem;
        font-size: 0.875rem;
        line-height: 1rem;
        letter-spacing: +1px;
      }
    .period {
      margin-top: 0.625rem;
      font-size: 0.775rem;
      line-height: 1rem;
      text-transform: uppercase;
      letter-spacing: +1px;
    }
  }
`

const Experiences = ({ content }) => {
  const sectionDetails = content[0].node
  const experiences = content.slice(1, content.length)

  // visibleProject is needed to show which project is currently
  // being viewed in the horizontal slider on mobile and tablet
  const [visibleExperience, setVisibleExperience] = useState(1)

  // projects don't track the visibility by using the onScreen hook
  // instead they use react-visibility-sensor, therefore their visibility
  // is also stored differently
  const [onScreen, setOnScreen] = useState({})
  const handleOnScreen = el => {
    if (!onScreen[el]) {
      const updatedOnScreen = { ...onScreen }
      updatedOnScreen[el] = true
      setOnScreen(updatedOnScreen)
    }
  }

  const pVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  }

  useEffect(() => {
    // mobile and tablet only: set first project as visible in the
    // horizontal slider
    setVisibleExperience(1)
    // required for animations: set visibility for all projects to
    // "false" initially
    let initial = {}
    experiences.forEach(experience => {
      initial[experience.node.frontmatter.position] = false
    })
    setOnScreen(initial)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Required for animating the title
  const tRef = useRef()
  const tOnScreen = useOnScreen(tRef)
  const tVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  }

  return (
    <StyledSection id="experiences">
      <StyledContentWrapper>
        <motion.div
          ref={tRef}
          variants={tVariants}
          animate={tOnScreen ? "visible" : "hidden"}
        >
          <h3 className="section-title">{sectionDetails.frontmatter.title}</h3>
        </motion.div>
        <div className="experiences">
          {experiences.map(experience => {
            const { body, frontmatter } = experience.node
            return (
              <VisibilitySensor
              key={frontmatter.position}
              onChange={() => handleOnScreen(frontmatter.position)}
              partialVisibility={true}
              minTopValue={100}
              >
                <StyledExperience
                  position={frontmatter.position}
                  variants={pVariants}
                  animate={
                    onScreen[frontmatter.position] ? "visible" : "hidden"
                  }
                  >
                  <div className="details">
                    <div className="category">
                      {frontmatter.category}
                    </div>
                    <div className="title">{frontmatter.title}</div>
                    <div className="period">{frontmatter.period}</div>
                    <div className="where">{frontmatter.where}</div>
                    {/*<MDXRenderer>{body}</MDXRenderer>*/}
                  </div>
                </StyledExperience>
              </VisibilitySensor>
            )
          })}
        </div>
      </StyledContentWrapper>
    </StyledSection>
  )
}

Experiences.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        body: PropTypes.string.isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Experiences
